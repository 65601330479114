<template>
    <div>
        <modal :name="modal_name" class="final-modal increase-width-popups-refund popup-modal-all-width-height-height-600" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title text-white" >REFUND</span>
                    <span @click="hideRefundPop">
                        <i class="icon icon-close pointer text-white"></i>
                    </span>
                </div>
                <div class="general-section" style="padding: 15px 0px;">
                <div class="v-modal-body">
                    <div class="modal-layout-box d-flex align-items-center justify-content-center" v-if="ajax_loader">
                        <div class="digi-table-loader row justify-content-center" style="margin: 0 auto">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div class="modal-layout-box" v-else>
                        <div class="table-view-data">
                            <table class="w-100 table-view-data-table">
                                <thead>
                                    <tr style="border-bottom: solid 1px #c5c4c4;">
                                        <th class="whiteSpace text-secondary fw-500">
                                            S.NO
                                        </th>
                                        <th class="whiteSpace text-secondary fw-500">
                                            <el-checkbox class="checkbox-blue-back" v-model="check_all" @change="checkAll"></el-checkbox>
                                        </th>
                                        <th class="whiteSpace text-secondary fw-500">
                                            Name
                                        </th>
                                        <th class="whiteSpace text-secondary fw-500">
                                            Quantity
                                        </th>
                                        <th class="whiteSpace text-secondary fw-500 text-right">
                                            Sub-Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(items, index) in refund_details" :key="index+'indexf'">
                                        <td class="whiteSpace">
                                            {{index+1}}
                                        </td>
                                        <td class="whiteSpace">
                                            <el-checkbox class="checkbox-blue-back" v-model="items.checked" @change="selectItem($event, items.item_id, index)" ></el-checkbox>
                                        </td>
                                        <td class="whiteSpace">
                                            {{items.name}}
                                        </td>
                                        <td class="whiteSpace">
                                            <div v-if="items.checked" class="d-flex align-items-center">
                                                <span>
                                                    <img v-if="items.quantity <= 1" src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" class="img-add-button-svg" style="height: 20px !important; width: 20px !important; cursor: not-allowed;" height="20"  width="20" id="decrease_qty" />
                                                    <img v-else @click="decreaseItemQty(items)" src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" style="height: 20px !important; width: 20px !important;" height="20"  width="20" id="decrease_qty" />
                                                </span>
                                                <span class="px-1">
                                                    <input @input="quantityInputChange($event, items)" class="quantity-number-input" v-model="items.quantity" type="number" v-on:keypress="isNumber($event)" />
                                                </span>
                                                <span>
                                                    <img v-if="items.quantity >= items.stored_quantity" src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" class="img-add-button-svg" style="height: 20px !important; width: 20px !important;cursor: not-allowed;" height="20"  width="20" id="increase_qty" />
                                                    <img v-else @click="increaseItemQty(items)" src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" class="img-add-button-svg" style="height: 20px !important; width: 20px !important;" height="20"  width="20" id="increase_qty" />
                                                </span>
                                            </div>
                                            <div v-else class="d-flex align-items-center">
                                                <span>
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" class="img-add-button-svg" style="height: 20px !important; width: 20px !important; cursor: not-allowed;" height="20"  width="20" id="decrease_qty" />
                                                </span>
                                                <span class="px-1">
                                                    <input disabled class="quantity-number-input" v-model="items.quantity" type="number" v-on:keypress="isNumber($event)" />
                                                </span>
                                                <span>
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" class="img-add-button-svg" style="height: 20px !important; width: 20px !important;cursor: not-allowed;" height="20"  width="20" id="increase_qty" />
                                                </span>
                                            </div>
                                        </td>
                                        <td class="whiteSpace text-right">
                                            {{items.item_price_formatted.substring(0,1)}} {{items.quantity*items.item_price}}
                                        </td>
                                    </tr>
                                    <tr style="height: 15px !important;">
                                        <td colspan="5" style="padding: 0 !important; height: 0 !important;">
                                            <div style="border-top: solid 1px #c5c4c4;">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="refund_calculated">
                                        <td class="whiteSpace">
                                        </td>
                                        <td class="whiteSpace">
                                        </td>
                                        <td colspan="2" class="whiteSpace text-secondary fw-600" style="border-bottom: dashed 1.5px #709fe3;">
                                            Items Total
                                        </td>
                                        <td class="whiteSpace fw-600 text-right" style="color: #1d2126;border-bottom: dashed 1.5px #709fe3;">
                                            <span class="mr-1" style="visibility: hidden; opacity: 0;">(-)</span>{{calculated_refund_amount.sub_total}}
                                        </td>
                                    </tr>
                                    <tr v-if="refund_calculated">
                                        <td class="whiteSpace">
                                        </td>
                                        <td class="whiteSpace">
                                        </td>
                                        <td colspan="2" class="whiteSpace text-secondary fw-600" style="border-bottom: dashed 1.5px #709fe3;">
                                            Order discount
                                        </td>
                                        <td class="whiteSpace fw-600 text-danger text-right" style="border-bottom: dashed 1.5px #709fe3;">
                                            <span class="mr-1">(-)</span>{{calculated_refund_amount.order_discount}}
                                        </td>
                                    </tr>
                                    <tr v-if="refund_calculated">
                                        <td class="whiteSpace">
                                        </td>
                                        <td class="whiteSpace">
                                        </td>
                                        <td colspan="2" class="whiteSpace text-secondary fw-600" style="border-bottom: dashed 1.5px #709fe3;">
                                            Items Total Including Tax & Other Charges
                                        </td>
                                        <td class="whiteSpace fw-600 text-right" style="color: #1d2126;border-bottom: dashed 1.5px #709fe3;">
                                            <span class="mr-1" style="visibility: hidden; opacity: 0;">(-)</span>{{calculated_refund_amount.sub_total_with_other_charge_and_tax}}
                                        </td>
                                    </tr>
                                    <tr v-if="refund_calculated">
                                        <td class="whiteSpace">
                                        </td>
                                        <td class="whiteSpace">
                                        </td>
                                        <td colspan="2" class="whiteSpace fw-600" style="color: #000;border-bottom: dashed 1.5px #709fe3;" >
                                            Paid Total
                                        </td>
                                        <td class="whiteSpace fw-600 text-right" style="color: #000;border-bottom: dashed 1.5px #709fe3;">
                                            <span class="mr-1" style="visibility: hidden; opacity: 0;">(-)</span>{{calculated_refund_amount.old_grand_total}}
                                        </td>
                                    </tr>
                                    <tr v-if="refund_calculated">
                                        <td class="whiteSpace">
                                        </td>
                                        <td class="whiteSpace">
                                        </td>
                                        <td colspan="2" class="whiteSpace text-success fw-600" >
                                            Refund Value
                                        </td>
                                        <td class="whiteSpace fw-600 text-success text-right">
                                            <span class="mr-1" style="visibility: hidden; opacity: 0;">(-)</span>{{calculated_refund_amount.refund_amount}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="!refund_calculated" class="text-right my-3">
                            <button class="btn btn-secondary" @click="calculateOverallRefund" :disabled="cal_refund_item_selected.length<1" type="button" style="border-radius: 5px !important;">CALCULATE REFUND</button>
                        </div>
                        <div class="refund-text">
                            <span class="fw-bold fs-16 text-secondary" style="line-height: 1;">
                                Mode of payment
                            </span>
                        </div>
                        <div class="d-flex align-items-center justify-content-between mt-3">
                            <h1 class="text-secondary mb-0" style="font-size: 15px;">{{mode_of_payment || 'CASH'}}</h1>
                            <span>
                                <img width="30" src="/static/images/cash_icon.svg" :alt="mode_of_payment || 'Cash'" />
                            </span>
                        </div>
                        <div class="hr-line my-3"></div>
                        <div class="refund-text mt-8">
                            <span class="fw-bold fs-16 text-secondary" style="line-height: 1;">
                                Comments
                            </span>
                        </div>
                        <div class="textarea-input">
                            <textarea class="w-100" rows="3" v-model="comments" placeholder="Write comments here"></textarea>
                        </div>
                    </div>
                </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5 d-block">
                    <div class="d-flex align-items-center justify-content-between">
                        <button class="btn btn-outline-secondary pointer fw-600" @click="hideRefundPop" style="width: 48.5%; border-radius: 5px !important;">CANCEL</button>
                        <button class="btn-refund pointer fw-600" :disabled="!refund_calculated" style="width: 48.5%;" @click="refundOrder">PAY REFUND</button>
                    </div>
                </div>
            </div>
        </modal>
        <sweet-modal ref="success_modal" icon="success">
            {{ modal_msg }}
        </sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">
            {{ modal_msg }}
        </sweet-modal>
    </div>
</template>
<script>
import { SweetModal } from "sweet-modal-vue";
import Avatar from 'vue-avatar'
import orderServices from './mixins/orders'
import printers from './mixins/printers';
import Swal from 'sweetalert2'
export default {
    mixins: [orderServices, printers],
    props:['modal_name', 'order_id', 'course_number', 'from_which_page'],
    data(){
        return{
            modal_msg:'',
            refund_details: [],
            comments: '',
            ajax_loader: false,
            items_total: 0,
            items_total_including_tax: 0,
            total_paid: 0,
            item_selected: [],
            cal_refund_item_selected: [],
            check_all: false,
            currency_symbol: '',
            calculated_refund_amount: {},
            refund_calculated: false,
            mode_of_payment: ''
        }
    },
    components:{
        Avatar,
        SweetModal
    },
    methods:{
        isNumber: function(evt,index) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        decreaseItemQty(items) {
            items.quantity = parseInt(items.quantity) - 1;
            this.cal_refund_item_selected.forEach((cal_refund) => {
                if(cal_refund.item_id == items.item_id) {
                    cal_refund.quantity = parseInt(items.quantity);
                }
            })
            this.refund_calculated = false;
        },
        increaseItemQty(items) {
            items.quantity = parseInt(items.quantity) + 1;
            this.cal_refund_item_selected.forEach((cal_refund) => {
                if(cal_refund.item_id == items.item_id) {
                    cal_refund.quantity = parseInt(items.quantity);
                }
            })
            this.refund_calculated = false;
        },
        quantityInputChange(event, items) {
            if(event.target.value > items.stored_quantity) {
                items.quantity = items.stored_quantity;
                this.$toasted.global.error("Quantity cannot be greater than the stored quantity");
            } else if(event.target.value == 0) {
                // items.quantity = 1;
                this.$toasted.global.error("Quantity cannot be less than one");
            } else {
                items.quantity = event.target.value;
            }
            this.cal_refund_item_selected.forEach((cal_refund) => {
                if(cal_refund.item_id == items.item_id) {
                    cal_refund.quantity = parseInt(items.quantity);
                }
            })
            this.refund_calculated = false;
        },
        hideRefundPop() {
            this.$emit('hideRefundPop');
        },
        async calculateOverallRefund() {
            console.log('calculate_refund');
            this.refund_calculated = false;
            let is_quantity_valid = true;
            this.cal_refund_item_selected.forEach((cal_ref, index)=>{
                if(cal_ref.quantity == 0 || cal_ref.quantity == '') {
                    is_quantity_valid = false;
                }
            })
            if (is_quantity_valid) {
                try {
                    let params = {
                        items: this.cal_refund_item_selected,
                        order_id: this.order_id
                    }
                    let response = await this.calculateRefund(params);
                    console.log(response);
                    this.calculated_refund_amount = response.response;
                    this.refund_calculated = true;
                }
                catch(e) {
                    console.log(e);
                    this.modal_msg = e;
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 2000);
                }
            } else {
                this.$toasted.global.error("Quantity cannot be empty and cannot be zero");
            }
        },
        async getRefundData() {
            this.ajax_loader = true;
            try {
                let params;
                if(this.from_which_page == 'view_order') {
                    params = {
                        course: this.course_number,
                        order_id: this.order_id,
                    };
                } else if(this.from_which_page == 'order_history') {
                    params = {
                        order_id: this.order_id,
                        all_items: true
                    };
                }
                let response = await this.getRefundDetails(params);
                this.refund_details = response.response.items;
                this.mode_of_payment = response.response.mode_of_payment
                this.refund_details.forEach((refund, index) => {
                    refund['checked'] = false;
                    this.currency_symbol = refund.item_price_formatted
                    refund['stored_quantity'] = parseInt(JSON.stringify(JSON.parse(refund.quantity)));
                })
                this.ajax_loader = false;
            }
            catch (error) {
                this.modal_msg = error;
                this.$refs.warning_modal.open();
                setTimeout(() => {
                    this.$refs.warning_modal.close();
                    this.hideRefundPop();
                }, 2000);
                // this.ajax_loader = false;
            }
        },
        selectItem(event, id, index) {
            if(event) {
                this.item_selected.push(id);
                this.cal_refund_item_selected.push({
                    item_id: id,
                    quantity: parseInt(this.refund_details[index].quantity)
                })
                this.items_total = this.items_total + parseFloat(this.refund_details[index].item_price);
                this.items_total_including_tax = this.items_total_including_tax + parseFloat(this.refund_details[index].item_price_including_tax);
            } else {
                let index = this.item_selected.indexOf(id);
                if (index > -1) {
                    this.item_selected.splice(index, 1);
                }
                let index_of_cal_refund = this.cal_refund_item_selected.findIndex(object => {
                    return object.item_id === id;
                });
                if (index_of_cal_refund > -1) {
                    this.cal_refund_item_selected.splice(index, 1);
                }
                this.items_total = this.items_total - parseFloat(this.refund_details[index].item_price);
                this.items_total_including_tax = this.items_total_including_tax - parseFloat(this.refund_details[index].item_price_including_tax);
            }
            if(this.item_selected.length === this.refund_details.length) {
                this.check_all = true;
            } else {
                this.check_all = false;
            }
            this.total_paid = this.items_total + this.items_total_including_tax
            this.refund_calculated = false;
        },
        checkAll(event) {
            if(event === true) {
                this.refund_details.forEach((refund, index) => {
                    this.refund_details[index].checked = true;
                    this.cal_refund_item_selected.push({
                        item_id: refund.item_id,
                        quantity: parseInt(refund.quantity)
                    })
                    this.item_selected.push(refund.item_id);
                    this.items_total = this.items_total + parseFloat(this.refund_details[index].item_price);
                    this.items_total_including_tax = this.items_total_including_tax + parseFloat(this.refund_details[index].item_price_including_tax);
                })
            } else {
                this.refund_details.forEach((refund, index) => {
                    this.refund_details[index].checked = false;
                    this.items_total = 0;
                    this.items_total_including_tax = 0;
                })
                this.item_selected = [];
                this.cal_refund_item_selected = [];
            }
            this.total_paid = this.items_total + this.items_total_including_tax
            this.refund_calculated = false;
        },
        async refundOrder() {
            let is_quantity_valid = true;
            this.cal_refund_item_selected.forEach((cal_ref, index)=>{
                if(cal_ref.quantity == 0 || cal_ref.quantity == '') {
                    is_quantity_valid = false;
                }
            })
            if (is_quantity_valid) {
                try {
                    let response = await this.saveRefundData(this.save_data);
                    this.modal_msg = response.message;
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.hideRefundPop();
                        this.confirmPrint(response.receipt_details)
                    }, 2000);
                }
                catch (error) {
                    this.modal_msg = error;
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 2000);
                }
            } else {
                this.$toasted.global.error("Quantity cannot be empty and cannot be zero");
            }
        },
        confirmPrint(receipt_data) {
            if(this.loggedInUser.print_receipt_by_default) {
                this.store_receipt_data = receipt_data
                // if(ticket_data && ticket_data.length){
                //     if(ticket_data.length == 1){
                //         this.store_ticket_data = ticket_data[0]
                //         if(!this.ticket_printing){
                //             this.printTicket(ticket_data[0])
                //         }
                //     }
                //     else if(ticket_data.length > 1){
                //         ticket_data.forEach(x => {
                //             this.store_ticket_data = x
                //             if(!this.ticket_printing){
                //                 this.printTicket(this.store_ticket_data)
                //             }
                //         })
                //     }
                // }
                this.printReceipt(receipt_data)
                if(this.loggedInUser.enable_double_receipts) {
                    setTimeout(() => {
                        this.printReceipt(receipt_data)
                    }, 5000);
                }
            } else {
                Swal({
                    title: 'Print Receipts',
                    text: 'Do You Want to Print Receipt ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Continue Without Printing',
                    allowOutsideClick: false
                }).then(result => {
                    if (result.value) {
                        this.store_receipt_data = receipt_data
                        // if(ticket_data && ticket_data.length){
                        //     if(ticket_data.length == 1){
                        //         this.store_ticket_data = ticket_data[0]
                        //         if(!this.ticket_printing){
                        //             this.printTicket(ticket_data[0])
                        //         }
                        //     }
                        //     else if(ticket_data.length > 1){
                        //         ticket_data.forEach(x => {
                        //             this.store_ticket_data = x
                        //             if(!this.ticket_printing){
                        //                 this.printTicket(this.store_ticket_data)
                        //             }
                        //         })
                        //     }
                        // }
                        this.printReceipt(receipt_data)
                        if(this.loggedInUser.enable_double_receipts) {
                            setTimeout(() => {
                                this.printReceipt(receipt_data)
                            }, 5000);
                        }
                    } else {
                        this.$modal.hide(this.modal_name)
                    }
                })
            }
        },
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        save_data() {
            return {
                order_id: this.order_id,
                items: this.cal_refund_item_selected,
                comments: this.comments,
            }
        }
    },
    created(){
    },
    mounted(){
        this.getRefundData();
    }
}
</script>
<style scoped>
.whiteSpace{
    white-space: nowrap;
}
.v-modal-layout{
    overflow: initial !important;
}
.final-modal .v-modal-header {
    background-color: #00448b !important;
}
.final-modal .v-modal-title {
    color: #fff !important;
}
.modal-layout-box {
    padding: 16px 12px 12.5px 11.8px;
    border-radius: 5px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px #206ad3;
    background-color: #fff;
}
.refund-text {
    margin: 0 0.3px 6.5px;
    padding: 2px 6px;
    background-color: #eeebeb;
}
.hr-line {
    border-top: dashed 2px #709fe3;
}
.textarea-input textarea {
    padding: 4.5px 4px 7.5px 4px;
    border-radius: 2px;
    border: solid 0.5px #cfcece;
    background-color: #fff;
    box-shadow: none;
}
.btn-refund{
    padding: 7px 16px 5.1px 16px;
    border-radius: 5px;
    background-image: linear-gradient(to left, #71e2a2, #07a163);
    outline: 0 none !important;
    box-shadow: none;
    color: #fff;
    height: 3.6rem;
}
.table-view-data-table {
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0;
    /* border-radius: 5px;
    border: solid 0.5px #cfcece;
    background-color: #fff; */
}
.table-view-data-table thead tr th,
.table-view-data-table tbody tr td {
    padding: 6px 10px;
}
.quantity-number-input {
    border-radius: 2px;
    border: solid 1.5px #00448b;
    outline: 0 none !important;
    color: #00448b;
    width: 50px;
    height: 20px;
    text-align: center;
    padding: 4px;
}
</style>